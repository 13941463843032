/**
* Generated automatically at built-time (2024-11-11T15:12:04.082Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "myoutdoorkitchen-stainless-collection",templateKey: "sites/100-5ce66e20-5367-440a-ae80-aa483d64de0a"};